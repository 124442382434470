
import { Component, Vue } from "vue-property-decorator";

@Component
export default class InscribeteCesfam extends Vue {

    private toLink(item: any, blank: any) {
        if (item && item != "") {
            if (blank == 1) {
                window.open(item, "_blank");
            } else {
                window.open(item, "_self");
            }
        } else {
            return;
        }
    }
}
