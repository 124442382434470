
import { Component, Vue } from "vue-property-decorator";
import InformacionPaciente from "@/components/Menu/InformacionPaciente.vue";

@Component({
    components: {
        InformacionPaciente,
    }
})
export default class InformacionPacienteView extends Vue {
    private mounted() {
        document.title = "Información al usuario - Maipú Salud";
    }
}
