
import { Component, Vue } from "vue-property-decorator";
import InscribeteCesfam from "@/components/Menu/InscribeteCesfam.vue";

@Component({
    components: {
        InscribeteCesfam,
    }
})
export default class InscribeteCesfamView extends Vue {
    private mounted() {
        document.title = "Busca tu Cesfam - Maipú Salud";
    }
}
