
import { Component, Vue } from "vue-property-decorator";
import PrestacionSaludProgramas from "@/components/Menu/PrestacionSaludProgramas.vue";

@Component({
    components: {
        PrestacionSaludProgramas,
    }
})
export default class PrestacionSaludProgramasView extends Vue {
    private mounted() {
        document.title = "Prestaciones de Salud y Programas - Maipú Salud";
    }
}
